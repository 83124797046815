<template>
    <div class="w-p-100 h-p-100 bg-f3">
      <van-form
        class="h-p-100 scroll-y w-p-100"
        @submit="onSubmit"
        ref="detailSubmit"
      >
        <van-cell-group inset class="h-calc-45 scroll-y margin-0 bg-transparent">
            <van-field
            v-model="state.risk_judgement_date" disabled
            name="calendar"
            placeholder="点击选择日期"
            @click="showCalendar = true"
            label="日期"
            />


            <!-- 企业状态 -->
            <div class=" tM-10 Fbg size-14 padding-10">
                <div class="w-p-100 bP-10 font-bold">企业状态</div>
                <div class="bM-20">
                    <div>1. 是否处于试生产？</div>
                    <div>
                        <van-radio-group v-model="detailForm.is_trail_production" style="border:none" >
                            <van-radio v-for="(items,index) in options.cemschoice" :key="index" :name="Number(items.PARAMETER_VALUE)">{{ items.PARAMETER_NAME }}</van-radio>
                        </van-radio-group>
                    </div>
                </div>
                <div class="bM-20" v-if="detailForm.is_trail_production==1">
                    <!-- <van-field v-model="item.commention"/> -->
                    <div>装置：</div>
                    <van-checkbox-group v-model="detailForm.is_trail_production_choice">
                        <van-checkbox v-for="(items,index) in options.unitchoice" :key="index" :name="items.value.toString()">{{items.label}}</van-checkbox>
                    </van-checkbox-group>
                    <el-input v-if="detailForm.is_trail_production_choice.includes('3')" v-model="detailForm.is_trail_production_commention" placeholder="" style="margin-bottom: 6px;"></el-input>
                </div>

                <div class="bM-20">
                    <div>2. 是否处于开停车状态？</div>
                    <div>
                        <van-radio-group v-model="detailForm.is_stop" style="border:none" >
                            <van-radio v-for="(items,index) in options.cemschoice" :key="index" :name="Number(items.PARAMETER_VALUE)">{{ items.PARAMETER_NAME }}</van-radio>
                        </van-radio-group>
                    </div>
                </div>
                <div class="bM-20" v-if="detailForm.is_stop==1">
                    <!-- <van-field v-model="item.commention"/> -->
                    <div>装置：</div>
                    <van-checkbox-group v-model="detailForm.is_stop_choice">
                        <van-checkbox v-for="(items,index) in options.unitchoice" :key="index" :name="items.value.toString()">{{items.label}}</van-checkbox>
                    </van-checkbox-group>
                    <el-input v-if="detailForm.is_stop_choice.includes('3')" v-model="detailForm.is_stop_commention" placeholder="" style="margin-bottom: 6px;"></el-input>
                </div>

                <div class="bM-20">
                    <div>3. 罐区等重大危险源是否处于安全状态？</div>
                    <div>
                        <van-radio-group v-model="detailForm.is_safe" style="border:none" >
                            <van-radio v-for="(items,index) in options.cemschoice" :key="index" :name="Number(items.PARAMETER_VALUE)">{{ items.PARAMETER_NAME }}</van-radio>
                        </van-radio-group>
                    </div>
                </div>
                <div class="bM-20" v-if="detailForm.is_safe==2">
                    <van-field v-model="detailForm.is_safe_commention"/>
                </div>
            </div>
            <div class=" tM-10 Fbg size-14 padding-10">
                <van-field
                v-model="detailForm.total_num"
                name="total_num"
                label="生产装置总数"
                readonly
                />
                <van-field
                v-model="detailForm.running_num"
                name="running_num"
                label="运行中"
                @input="calcTotal()"
                />
                <van-field
                v-model="detailForm.stop_num"
                name="stop_num"
                label="停产中"
                @input="calcTotal()"
                />
                <van-field
                v-model="detailForm.repairing_num"
                name="repairing_num"
                label="检修中"
                @input="calcTotal()"
                />
            </div>
            <div class=" tM-10 Fbg size-14 padding-10">
                <van-field
                v-model="detailForm.work_type_total"
                name="work_type_total"
                label="特殊危险作业总数"
                readonly
                />
                <van-field
                v-for="(item,index) in matrixHeader" :key="index"
                v-model="detailForm['work_type_'+item.prop]"
                :name="item.label"
                :label="item.label"
                @input="calcDanger"
                />
            </div>
            
            <!-- 人员状态 -->
            <div class=" tM-10 Fbg size-14 padding-10">
                <div class="w-p-100 bP-10 font-bold">人员状态</div>
                <van-field
                v-model="detailForm.prod_cepsa_today"
                name="prod_cepsa_today"
                label="制造部门当日员工总数"
                />
                <van-field
                v-model="detailForm.prod_contractor_today"
                name="prod_contractor_today"
                label="制造部门当日承包商总人数"
                />
            </div>
            <!-- 备注 -->
            <!-- <div class=" tM-10 Fbg flex flex-warp size-14 padding-10">
                <div class="w-p-100 bP-10 font-bold">备注</div>
                <van-field
                v-model="detailForm.commention"
                name="commention"
                />
            </div> -->
            
            <van-popup v-model:show="picker.ct_contractor_info_id" position="bottom">
                <div style="height:400px">
                    <div class="flex justifyBetween h-45">
                        <div class="h-p-100 flex justifyCenter alignCenter size-14 w-60" @click="picker.ct_contractor_info_id = false">取消</div>
                        <div class="h-p-100 flex justifyCenter alignCenter size-14 w-60" @click="confirmCT()">确认</div>
                    </div>
                    <div class="h-calc-45 lP-24">
                        <van-checkbox-group v-model="contractorIds"  ref="checkboxes">
                            <van-checkbox v-for="(item, index) in options.ct_contractor_info_id" :key="index" 
                                :name="item.value.toString()">{{item.label}}
                            </van-checkbox>
                        </van-checkbox-group>
                    </div>
                </div>
            </van-popup>
            <van-calendar
            v-model:show="showCalendar"
            @confirm="calendarOnConfirm"
            :min-date="new Date('2000-01-01')"
            />
        </van-cell-group>
        <!-- 签名 -->
        <handwritingHtml
            :showVisible="showSignDialog" :echoSignImage = "detailForm.signature"
            @writingCommit="writingCommit"
            @close="showSignDialog = false"
            ref="wirteDialog"
        ></handwritingHtml>
        <div class="flex">
          <van-button
            block
            type="primary"
            @click="save(0)"
            v-if="(permission.insertflag==1 ||  permission.updateflag == 1)&&detailForm.status == 1"
            :disabled="btnLoading"
            :loading="btnLoading"
            >保存</van-button
          >
          <van-button
            block
            type="primary"
            @click="save(1)"
            v-if="(permission.insertflag==1 ||  permission.updateflag == 1)&&detailForm.status == 1&&can_submit==1"
            :disabled="btnLoading"
            :loading="btnLoading"
            >提交</van-button
          >
          <van-button
            block
            type="primary"
            @click="save(1)"
            v-if="(permission.insertflag==1 ||  permission.updateflag == 1)&&detailForm.status == 1&&can_help_submit==1"
            :disabled="btnLoading"
            :loading="btnLoading"
            >代提交</van-button
          >
          <van-button
            block
            type="default"
            class="bg-808080 color-fff"
            @click="goBack()"
            >返回</van-button
          >
        </div>
      </van-form>
    </div>
</template>
<script setup>
/* eslint-disable */
import { onMounted,reactive, ref} from 'vue';
import http from '../../../api/http';
import {Toast,Notify,Dialog ,ImagePreview} from 'vant'
import { remove } from '@vue/shared';
import dayjs from 'dayjs';
import { useRouter,useRoute } from 'vue-router';
import handwritingHtml from '../../../components/sign.vue';

const router = useRouter();
const route = useRoute(); 

import global from '../../../api/global';
const permission = reactive(global.getPagePermission(route.meta.parent)); 

const showCalendar = ref(false)
const calendarOnConfirm = (date) =>{
    detailForm.risk_judgement_date =  dayjs(date).format('YYYY-MM-DD');//`${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    state.risk_judgement_date =  dayjs(date).format('YYYY-MM-DD');
    showCalendar.value = false;
}
const goBack = ()=>{
        router.push({
            path:'/PIC',
            query:route.query
        })
}
const detailSubmit = ref(null);
const detailForm = reactive({
    method:'query',
    queryId:3019,
    ct_contractor_info_id:'',
    id:'',
    cepsa_num:'',
    contractor_num:'',
    commention:'',
    risk_judgement_date:dayjs().format('YYYY-MM-DD'),
    total_num:'',
    stop_num:'',
    running_num:'',
    repairing_num:'',
    status:'',
    create_by:'',
    create_time:'',
    update_by:'',
    update_time:'',
    work_type_total:'',
    work_type_1:'',
    work_type_2:'',
    work_type_3:'',
    work_type_4:'',
    work_type_5:'',
    work_type_6:'',
    work_type_7:'',
    work_type_8:'',
    work_type_9:'',
    work_type_10:'',
    is_trail_production:'',
    is_safe:'',
    is_stop:'',
    prod_cepsa_today:'',
    prod_contractor_today:'',
    signature:'',
    is_trail_production_choice:[],
    is_trail_production_commention:'',
    is_stop_choice:[],
    is_stop_commention:'',
    is_safe_choice:[],
    is_safe_commention:''
});
const state = reactive({
    method:'query',
    queryId:3019,
    ct_contractor_info_id:'',
    id:'',
    cepsa_num:'',
    contractor_num:'',
    commention:'',
    risk_judgement_date:dayjs().format('YYYY-MM-DD'),
    total_num:'',
    stop_num:'',
    running_num:'',
    repairing_num:'',
    status:'',
    create_by:'',
    create_time:'',
    update_by:'',
    update_time:'',
    work_type_total:'',
    work_type_1:'',
    work_type_2:'',
    work_type_3:'',
    work_type_4:'',
    work_type_5:'',
    work_type_6:'',
    work_type_7:'',
    work_type_8:'',
    work_type_9:'',
    work_type_10:'',
    is_trail_production:'',
    is_safe:'',
    is_stop:'',
    prod_cepsa_today:'',
    prod_contractor_today:'',
    signature:'',
    is_trail_production_choice:[],
    is_trail_production_commention:'',
    is_stop_choice:[],
    is_stop_commention:'',
    is_safe_choice:[],
    is_safe_commention:''
});
const checkboxes = ref(null)
const contractorIds = ref([])
const toggle = (item, index) =>{
    checkboxes[index].toggle();
    // 使用 findIndex 查找数组中是否已经存在相同的元素
    const idx = contractorIds.value.findIndex(ele => ele.value === item.value);
    if (idx > -1) {
        // 如果数组中已经存在该元素，则将其删除
        contractorIds.value.splice(idx, 1);
    } else {
        // 否则将其添加到数组中
        contractorIds.value.push(item);
    }
}
const confirmCT = () =>{
    detailForm.ct_contractor_info_id = contractorIds.value.join(','); 

    let show = []
    if(contractorIds.value.length>0){
        contractorIds.value.forEach(item=>{
            const elem = options.ct_contractor_info_id.find(e=>e.value==item);
            if(elem){
                show.push(elem.label);
                
            }  
        })
    }

    state.ct_contractor_info_id = show.join(',');
    picker.ct_contractor_info_id = false;
}
const matrixHeader = ref([
    {label:'特级动火',prop:1},
    {label:'一级动火',prop:2},
    {label:'二级动火',prop:3},
    {label:'动土',prop:4},
    {label:'断路',prop:5},
    {label:'受限空间',prop:6},
    {label:'临时用电',prop:7},
    {label:'登高',prop:8},
    {label:'盲板抽堵',prop:9},
    {label:'吊装',prop:10},
])
const calcTotal = () =>{
    detailForm.total_num = Number(detailForm.stop_num) + Number(detailForm.running_num) + Number(detailForm.repairing_num)
}
const calcDanger = () =>{
    let total = 0;
    matrixHeader.value.forEach(elem=>{
        total = total + Number(detailForm['work_type_'+elem.prop])
    })
    detailForm.work_type_total = total;
}

const loading = ref(false);
const list = ref([]);
const fileList = ref([]);

const options = reactive({
    ct_contractor_info_id:[],
    cemschoice:[],
    unitchoice:[
        {label:'异丙苯装置',value:1},
        {label:'苯酚丙酮装置',value:2},
        {label:'其他',value:3}
    ]
});
const picker = reactive({
    ct_contractor_info_id:false,
});

const getSelectList = () =>{
    http.get({
        method:'listMultiPardata',
        t:'RISK_JUDGEMENT',
        prop:'ct_contractor_info_id'
    },true)
    .then(res=>{
        options.ct_contractor_info_id=res.resultData.ct_contractor_info_id;
        getDetail(route.params.id);

    })
    http.get({
        method:'query',
        queryId:3008,
        parameter_types:"RISK_JUDGEMENT_CHOICE"
    },true)
    .then(res=>{
        options.cemschoice = res.resultData.RISK_JUDGEMENT_CHOICE;
    })
}


const btnLoading = ref(false);
const showSignDialog = ref(false);
// 上传签名
const writingCommit = (res)=>{
    showSignDialog.value = false
    detailForm.signature = res;
    saveRequest(1)
    
}
const save = (type) =>{
    if(detailForm.is_trail_production==1){
        if(detailForm.is_trail_production_choice.length==0 || (detailForm.is_trail_production_choice.includes('3')&&detailForm.is_trail_production_commention=='')||
        (detailForm.is_trail_production_choice.includes('3')&&detailForm.is_trail_production_commention==null)){
            Notify({ type: 'warning', message: '请填写基础信息选项备注!' });
            return
        }
    }
    if(detailForm.is_stop==1){
        if(detailForm.is_stop_choice.length==0 || (detailForm.is_stop_choice.includes('3')&&detailForm.is_stop_commention=='')||
        (detailForm.is_stop_choice.includes('3')&&detailForm.is_stop_commention==null)){
            Notify({ type: 'warning', message: '请填写基础信息选项备注!' });
            return
        }
    }

    if (type!=0) {
        //判断用户是否已经签名
        if(detailForm.signature != null && detailForm.signature){
            showSignDialog.value = true
        }else {
            if(localStorage.getItem('app_user_id')){
                    http.get({ method:"detailUser", id:localStorage.getItem('app_user_id'), table:"SYS_USER"},true,true)
                .then(res=>{
                    if(res.resultData.map && res.resultData.map.signature_path){
                        detailForm.signature = res.resultData.map.signature_path;
                    }
                    showSignDialog.value = true
                })
                .catch(()=>{})
                }else {
                    showSignDialog.value = true
                }
        }
    } else {
        btnLoading.value = true;
        saveRequest(type);
    }
}
const saveRequest = (type,noMsg) =>{
    var params = Object.assign({}, detailForm)
    if(type==1){
        params['action']='success'
    }
    http.post(params)
    .then(res=>{
        btnLoading.value = false;
        if(!noMsg){
            Notify({ type: 'success', message: '保存成功' });
        }
        getDetail(res.map)
        replaceRoute(res.map)
        getSelectList()
    })
    .catch(()=>{
        btnLoading.value = false;
    })
}

// 详情
const getDetail = (id) =>{
    loading.value = true
    http.get({
        method:'query',
        queryId:3018,
        id:id
    },true,false)
    .then(res=>{
        loading.value = false
        var dMap = res.resultData.map;
        Object.keys(detailForm).map(key=>{
            if(key!='method'&&key!='t'&&key!='queryId'){
                if(key=='is_trail_production_choice'||key=='is_stop_choice'){
                    detailForm[key] = dMap[key]?dMap[key]:[]
                    state[key] = dMap[key]?dMap[key]:[]
                }else{
                    detailForm[key] = dMap[key]
                    state[key] = dMap[key]
                }       
            }
        });
        getStateDisplay()
    })
}
//处理回显
const getStateDisplay = () =>{
    contractorIds.value = detailForm.ct_contractor_info_id?detailForm.ct_contractor_info_id:[]

    let arr = []
    if(detailForm.ct_contractor_info_id && detailForm.ct_contractor_info_id.length>0){
        detailForm.ct_contractor_info_id.forEach(item=>{
            let elem = options.ct_contractor_info_id.find(e=>e.value==item)
            if(elem){
                arr.push(elem.label)
            }   
        })
    }
    state.ct_contractor_info_id = arr.join(',')

    state.risk_judgement_date = dayjs(detailForm.risk_judgement_date).format('YYYY-MM-DD')
}
// 新增编辑之后执行事件
const replaceRoute = (id) =>{
    router.replace({
        name:'PICDetail',
        params:{
            id:id
        }
    })
}
const can_submit = ref(0)
const can_help_submit = ref(0)
const getPermission = () =>{
    http.get({
        method:'query',
        queryId:3026,
        t:'RISK_JUDGEMENT_CHARGER',
    },true)
    .then(res=>{
        can_submit.value = res.resultData.can_submit;
        can_help_submit.value = res.resultData.can_help_submit;
    })
}

onMounted(()=>{
    getPermission();
    if(route.params.id==0){
        saveRequest(0,true)
    }else{
        getSelectList();
    }
});
  </script>